import styled from 'styled-components';
import { Button } from '@/components/new/Button';
import { Pagination } from '@/components/new/Pagination';
import { Text } from '@/components/new/Text';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { useApiError } from '@/hooks/useApiError';
import { usePaginationVariables } from '@/hooks/usePaginationVariables';
import {
  useWalletValueTransactionsQuery,
  ValueTransactionDetailsFragment,
} from '@/graphql';
import {
  paginationLayouts,
  PaginationLayoutType,
} from '../../layouts/PaginationAndExportLayout';
import { ValueTransactionsTable } from '../shared';
import { ValueTransactionsSearchPanel } from './SearchPanel';
import { useExportValueTransactions } from './hooks/useExportValueTransactions';
import { useSearchVariables } from './hooks/useSearchVariables';

const PaginationWrapper = styled.div`
  margin-top: 8px;
`;

const ButtonWrapper = styled.div({
  '> button': {
    width: '160px',
  },
});

export const WalletTransactionsContent = (): JSX.Element => {
  const { handleQueryError } = useApiError();
  const paginationVariables = usePaginationVariables();
  const searchVariables = useSearchVariables();
  const { data, loading, error } = useWalletValueTransactionsQuery({
    variables: {
      ...paginationVariables,
      ...searchVariables,
    },
    onError: handleQueryError,
  });
  const [exportValueTransactions, { loading: exportValueTransactionsLoading }] =
    useExportValueTransactions();

  const hasNextPage =
    !!data?.currentOffice.wallet?.valueTransactions?.pageInfo.hasNextPage;
  const hasPreviousPage =
    !!data?.currentOffice.wallet?.valueTransactions?.pageInfo.hasPreviousPage;
  const startCursor =
    data?.currentOffice.wallet?.valueTransactions?.pageInfo.startCursor;
  const endCursor =
    data?.currentOffice.wallet?.valueTransactions?.pageInfo.endCursor;
  const valueTransactions = (
    data?.currentOffice.wallet?.valueTransactions?.edges ?? []
  ).reduce<ValueTransactionDetailsFragment[]>(
    (results, edge) => (edge?.node ? [...results, edge.node] : results),
    []
  );
  const displayPagination = hasPreviousPage || hasNextPage;

  const paginationStatus: PaginationLayoutType = displayPagination
    ? 'both'
    : 'onlyExport';

  const PaginationLayout = paginationLayouts[paginationStatus];

  return (
    <>
      <ValueTransactionsSearchPanel />

      <PaginationLayout>
        {displayPagination && (
          <Pagination
            endCursor={endCursor}
            startCursor={startCursor}
            hasPreviousPage={hasPreviousPage}
            hasNextPage={hasNextPage}
          />
        )}
        <ButtonWrapper>
          <Button
            loading={exportValueTransactionsLoading}
            onClick={() => exportValueTransactions({ ...searchVariables })}
            size="small"
            variant="outline"
          >
            CSVダウンロード
          </Button>
        </ButtonWrapper>
      </PaginationLayout>

      <GlobalMessage />

      <ValueTransactionsTable
        loading={loading}
        error={error}
        items={valueTransactions}
        noItemsMessage={
          <Text color="notes">絞り込み条件に一致する履歴がありません</Text>
        }
      />
      {displayPagination && (
        <PaginationWrapper>
          <Pagination
            endCursor={endCursor}
            startCursor={startCursor}
            hasPreviousPage={hasPreviousPage}
            hasNextPage={hasNextPage}
          />
        </PaginationWrapper>
      )}
    </>
  );
};
