import { Select } from '@moneyforward/cloud-react-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { FormError } from '@/components/ui/FormError';
import { AssetsAmountSelectOptions } from '@/lib/assetsAmount';
import { AssetsAmountSchemaData } from './schema';

export const AssetsAmountInput = (): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AssetsAmountSchemaData>();

  return (
    <>
      <Controller
        control={control}
        name="assetsAmount"
        render={({ field }) => (
          <Select
            {...field}
            aria-label="法人資産額"
            instanceId="assetsAmount"
            placeholder="選択してください"
            options={AssetsAmountSelectOptions}
            value={AssetsAmountSelectOptions.find(
              (option) => option.value === field.value
            )}
            onChange={(e) => field.onChange(e?.value ?? '')}
          />
        )}
      />
      {errors.assetsAmount && (
        <FormError>{errors.assetsAmount.message}</FormError>
      )}
    </>
  );
};
