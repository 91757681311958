import { Select } from '@moneyforward/cloud-react-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { FormError } from '@/components/ui/FormError';
import { IncomeSelectOptions } from '@/lib/income';
import { IncomeSchemaData } from './schema';

export const IncomeInput = (): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IncomeSchemaData>();

  return (
    <>
      <Controller
        control={control}
        name="income"
        render={({ field }) => (
          <Select
            {...field}
            aria-label="所得額"
            instanceId="income"
            placeholder="選択してください"
            options={IncomeSelectOptions}
            value={IncomeSelectOptions.find(
              (option) => option.value === field.value
            )}
            onChange={(e) => field.onChange(e?.value ?? '')}
          />
        )}
      />
      {errors.income && <FormError>{errors.income.message}</FormError>}
    </>
  );
};
