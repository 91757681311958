import { Select } from '@moneyforward/cloud-react-ui';
import { Option } from '@moneyforward/cloud-react-ui/lib/components/Select/Select';
import { Control, useController } from 'react-hook-form';
import { DepositWithdrawal } from '@/graphql';
import { FormValues } from './SearchPanel';

export type DepositWithdrawalType = {
  depositWithdrawalType: DepositWithdrawal;
};

const options: Option[] = [
  { label: 'すべて', value: DepositWithdrawal.Both },
  { label: '入金', value: DepositWithdrawal.Deposit },
  { label: '出金', value: DepositWithdrawal.Withdrawal },
];

export type DepositWithdrawalTypeSelectProps = {
  control: Control<FormValues>;
};
export const DepositWithdrawalTypeSelect = ({
  control,
}: DepositWithdrawalTypeSelectProps): JSX.Element => {
  const { field } = useController({
    name: 'depositWithdrawalType',
    control,
  });

  return (
    <label>
      入出金区分
      <Select
        {...field}
        aria-label="depositWithdrawalType"
        clearable={false}
        onChange={(e) => field.onChange(e?.value)}
        value={options.find((element) => element.value === field.value)}
        options={options}
      />
    </label>
  );
};
