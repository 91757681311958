import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { RepresentativeLastNameData } from './schema';

export const RepresentativeLastNameInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<RepresentativeLastNameData>();

  return (
    <div>
      <TextField
        aria-label="representativeLastName"
        {...register('representativeLastName')}
        placeholder="例）山田"
      />
      {errors.representativeLastName && (
        <FormError>{errors.representativeLastName.message}</FormError>
      )}
    </div>
  );
};
