import { Select } from '@moneyforward/cloud-react-ui';
import { Option } from '@moneyforward/cloud-react-ui/lib/components/Select/Select';
import { Controller, useFormContext } from 'react-hook-form';
import { Account } from '@/graphql';
import { FormError } from '../../FormError';

type InputProps = {
  accounts: Account[];
};

export const PostPayAccountInput = ({ accounts }: InputProps): JSX.Element => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const options = accounts.flatMap((account) => {
    return account.subAccounts.map((subAccount) => {
      return {
        value: subAccount.id,
        label: `${account.serviceName} ${subAccount.name} ${subAccount.type} ${subAccount.number}`,
      };
    });
  });

  const onPostPayAccountChanged = (e: Option) => {
    setValue('subAccountId', e.value);
  };

  return (
    <>
      <Controller
        control={control}
        name={'subAccountId'}
        render={({ field }) => (
          <Select
            {...field}
            aria-label="subAccountId"
            instanceId={'subAccountSelect'}
            options={options}
            value={options.find((option) => option.value === field.value)}
            onChange={(e) => {
              field.onChange(e.value);
              onPostPayAccountChanged(e);
            }}
            placeholder={'選択してください'}
            clearable={false}
          />
        )}
      />
      {errors.subAccountId && (
        <section data-testid="subAccountId">
          <FormError>{errors.subAccountId.message}</FormError>
        </section>
      )}
    </>
  );
};
