import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Text } from '@/components/new/Text';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { RegistrationAlert } from '@/components/ui/RegistrationAlert';
import { RegistrationButtons } from '@/components/ui/RegistrationButtons';
import { RegistrationTable } from '@/components/ui/RegistrationTable';
import { RegistrationTableRow } from '@/components/ui/RegistrationTableRow';
import {
  FirstNameInput,
  LastNameInput,
  FirstNameKanaInput,
  LastNameKanaInput,
  BirthDateInput,
  AddressInputs,
  PurposeInput,
  InputGroup,
  PhoneNumberInput,
} from '@/components/ui/inputs';
import { FinancialAssetsInput } from '@/components/ui/inputs/FinancialAssets';
import { FirstNameEnInput } from '@/components/ui/inputs/FirstNameEn';
import { IncomeInput } from '@/components/ui/inputs/Income';
import { LastNameEnInput } from '@/components/ui/inputs/LastNameEn';
import { MiddleNameEnInput } from '@/components/ui/inputs/MiddleNameEn';
import { NationalityInput } from '@/components/ui/inputs/Nationality';
import { ResidencePeriodEndDateInput } from '@/components/ui/inputs/ResidencePeriodEndDate';
import { ResidentialStatusInput } from '@/components/ui/inputs/ResidentialStatus';
import { UsagePurposesInput } from '@/components/ui/inputs/UsagePurpose';
import { UserIndustryInput } from '@/components/ui/inputs/UserIndustry';
import { transformQueryResult } from '@/lib/transform';
import { yupResolver } from '@/lib/yup';
import { useApiError } from '@/hooks/useApiError';
import {
  PurposeEnum,
  useUpdateIndividualIdentificationMutation,
  IndividualIdentificationDocument,
  IndividualIdentificationQuery,
} from '@/graphql';
import { FormData, schema } from './types';
import { useGoToStepsOrConfirmation } from '@/hooks';

const MiddleNameWrapper = styled.div`
  margin-top: 8px;
`;

const InputWrapper = styled.div`
  width: 260px;
`;

type Props = {
  data?: IndividualIdentificationQuery;
};

const Box = styled.div`
  margin-bottom: 8px;
`;
export const Form = ({ data }: Props): JSX.Element => {
  const { handleMutationError } = useApiError();
  const goToStepsOrConfirmation = useGoToStepsOrConfirmation();
  const [saveInfo, { loading }] = useUpdateIndividualIdentificationMutation({
    refetchQueries: [{ query: IndividualIdentificationDocument }],
    onCompleted: () => goToStepsOrConfirmation(),
    onError: (error) => handleMutationError(error),
  });
  const identificationPurposes =
    data?.currentOffice?.identificationVerificationRequest
      ?.identificationPurposes;

  const userIdentification =
    data?.currentOffice?.identificationVerificationRequest
      ?.individualIdentification?.userIdentification;
  const usagePurposes =
    data?.currentOffice?.identificationVerificationRequest?.usagePurposes ?? [];

  const applicant = {
    ...userIdentification,
    ...(userIdentification && {
      birthDate: transformQueryResult.iso8601Date(userIdentification.birthDate),
      postalCode: transformQueryResult.postalCode(
        userIdentification.postalCode
      ),
    }),
    firstNameEn: userIdentification?.firstNameEn ?? '',
    lastNameEn: userIdentification?.lastNameEn ?? '',
    middleNameEn: userIdentification?.middleNameEn ?? '',
    phoneNumber: userIdentification?.phoneNumber ?? '',
    income: userIdentification?.income ?? undefined,
    financialAssets: userIdentification?.financialAssets ?? undefined,
    userIndustry: userIdentification?.industry ?? undefined,
    usagePurposes: usagePurposes.map((item) => item.purpose),
    nationalityId: userIdentification?.nationality?.id ?? 'JP',
    residentialStatus: userIdentification?.residentialStatus ?? undefined,
    residencePeriodEndDate:
      userIdentification?.residencePeriodEndDate ?? undefined,
    // NOTE: Update these values if multiple purposes are added
    ...(identificationPurposes &&
      identificationPurposes.length > 0 && {
        purpose: PurposeEnum.SettlementOfBusinessExpenses,
        purposeItemId: identificationPurposes[0].itemId,
      }),
  };

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    context: { confirmAge: true },
    ...(applicant && {
      defaultValues: {
        ...applicant,
      },
    }),
  });

  const onSubmit = ({ usagePurposes, ...inputs }: FormData) => {
    if (loading) return;

    saveInfo({
      variables: {
        ...inputs,
        usagePurposes: usagePurposes.map((purpose) => ({ purpose })),
      },
    });
  };

  const isJapanese = methods.watch('nationalityId') === 'JP';

  return (
    <FormProvider {...methods}>
      <GlobalMessage />
      <RegistrationAlert />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <RegistrationTable header="本人情報">
          <RegistrationTableRow title="氏名">
            <InputGroup>
              <LastNameInput />
              <FirstNameInput />
            </InputGroup>
          </RegistrationTableRow>
          <RegistrationTableRow title="氏名（フリガナ）">
            <InputGroup>
              <LastNameKanaInput />
              <FirstNameKanaInput />
            </InputGroup>
          </RegistrationTableRow>
          <RegistrationTableRow title="氏名（英語表記）">
            <InputGroup>
              <LastNameEnInput />
              <FirstNameEnInput />
            </InputGroup>
            <MiddleNameWrapper>
              <MiddleNameEnInput />
            </MiddleNameWrapper>
          </RegistrationTableRow>
          <RegistrationTableRow title="生年月日">
            <BirthDateInput />
          </RegistrationTableRow>
          <RegistrationTableRow title="住所">
            <Box>
              <Text size="small">
                本人確認書類に記載されている住所を入力してください。
              </Text>
            </Box>
            <AddressInputs />
          </RegistrationTableRow>
          <RegistrationTableRow title="電話番号">
            <Box>
              <Text size="small">
                カスタマーサポートからお電話をさせていただく場合がございます。
              </Text>
            </Box>
            <PhoneNumberInput />
          </RegistrationTableRow>
          <RegistrationTableRow title="国籍">
            <NationalityInput />
          </RegistrationTableRow>
          {!isJapanese && (
            <>
              <RegistrationTableRow title="在留資格">
                <InputWrapper>
                  <ResidentialStatusInput />
                </InputWrapper>
              </RegistrationTableRow>
              <RegistrationTableRow title="在留期間（満了日）">
                <Box>
                  <Text size="small">
                    永住者や期限に定めのない場合は、在留カード等の「カード有効期限」を入力してください。
                  </Text>
                </Box>
                <InputWrapper>
                  <ResidencePeriodEndDateInput />
                </InputWrapper>
              </RegistrationTableRow>
            </>
          )}
          <RegistrationTableRow title="所得額">
            <Box>
              <Text size="small">
                世帯所得ではなく、ご自身の前年の所得を入力してください。
                <br />
                前年の所得がない場合は、今年の見込みを入力してください。
              </Text>
            </Box>
            <InputWrapper>
              <IncomeInput />
            </InputWrapper>
          </RegistrationTableRow>
          <RegistrationTableRow title="金融資産額">
            <Box>
              <Text size="small">
                現金やこれに準ずるもの（金・銀等）、預貯金、有価証券等の合計額を指し、土地・建物等の不動産、貯蓄型の生命保険や学資保険は含みません。
              </Text>
            </Box>
            <InputWrapper>
              <FinancialAssetsInput />
            </InputWrapper>
          </RegistrationTableRow>
          <RegistrationTableRow title="主な事業内容">
            <UserIndustryInput />
          </RegistrationTableRow>
          <RegistrationTableRow title="取引目的">
            <PurposeInput />
          </RegistrationTableRow>
          <RegistrationTableRow title="利用目的">
            <UsagePurposesInput />
          </RegistrationTableRow>
        </RegistrationTable>
        <RegistrationButtons />
      </form>
    </FormProvider>
  );
};
