import { Select } from '@moneyforward/cloud-react-ui';
import { forwardRef, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { convertToNationalityOptions } from '@/lib/nationality';
import { useApiError } from '@/hooks/useApiError';
import { useNationalitiesQuery } from '@/graphql';
import { FormError } from '../../FormError';
import { RepresentativeNationalityIdSchemaData } from './schema';

export const RepresentativeNationalityInput = forwardRef<HTMLInputElement>(
  (_, ref) => {
    const {
      control,
      formState: { errors },
    } = useFormContext<RepresentativeNationalityIdSchemaData>();

    const { handleQueryError } = useApiError();

    const { data } = useNationalitiesQuery({
      onError: handleQueryError,
    });

    const nationalities = data?.nationalities ?? [];

    const nationalityOptions = useMemo(
      () => convertToNationalityOptions(nationalities),
      [nationalities]
    );

    return (
      <>
        <Controller
          control={control}
          name={'representativeNationalityId'}
          render={({ field }) => (
            <Select
              {...field}
              ref={ref}
              aria-label={'国籍'}
              instanceId={'representativeNationalityId'}
              placeholder="選択してください"
              options={nationalityOptions}
              value={nationalityOptions.find(
                (option) => option.value === field.value
              )}
              onChange={(e) => field.onChange(e?.value ?? '')}
            />
          )}
        />
        {errors.representativeNationalityId && (
          <FormError>{errors.representativeNationalityId.message}</FormError>
        )}
      </>
    );
  }
);

RepresentativeNationalityInput.displayName = 'RepresentativeNationalityInput';
