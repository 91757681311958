import { Select } from '@moneyforward/cloud-react-ui';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PrefectureSelectOptions } from '@/lib/prefecture';

export const PrefectureInput = forwardRef<HTMLInputElement>((_, ref) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="prefectureCode"
      render={({ field }) => (
        <Select
          {...field}
          ref={ref}
          aria-label="prefectureCode"
          instanceId="prefectureCode"
          placeholder="都道府県"
          options={PrefectureSelectOptions}
          value={PrefectureSelectOptions.find(
            (option) => option.value === field.value
          )}
          onChange={(e) => field.onChange(e?.value ?? '')}
        />
      )}
    />
  );
});

PrefectureInput.displayName = 'PrefectureInput';
