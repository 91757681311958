import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Loading } from '@/components/screen';
import { GlobalMessage } from '@/components/ui/GlobalMessage';
import { RegistrationAlert } from '@/components/ui/RegistrationAlert';
import { RegistrationButtons } from '@/components/ui/RegistrationButtons';
import { useGoToStepsOrConfirmation } from '@/hooks/registration';
import { useApiError } from '@/hooks/useApiError';
import {
  CardColorEnum,
  useFirstCardAndCardNameQuery,
  usePatchCorporateIdentificationMutation,
  usePatchIndividualIdentificationMutation,
  useUpdateFirstCardRequestAndOfficeNameMutation,
} from '@/graphql';
import { FormData, schema } from './schema';

export type RegistrationFirstCardFormProps = {
  corporate?: true;
  children: React.ReactNode;
};
export const RegistrationFirstCardForm: React.FC<
  RegistrationFirstCardFormProps
> = ({ children, corporate }) => {
  const { handleMutationError, handleQueryError } = useApiError();
  const goToStepsOrConfirmation = useGoToStepsOrConfirmation();
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      color: CardColorEnum.Black,
      maximumMonthlyLimit: 2_000_000,
      maximumPaymentLimit: 1_000_000,
    },
  });
  const { loading } = useFirstCardAndCardNameQuery({
    fetchPolicy: 'cache-and-network',
    onError: handleQueryError,
    onCompleted: (data) => {
      const firstCardRequest = data.currentOffice.firstCardRequest;
      const cardName = data.currentOffice.cardName;
      const corporateIdentification =
        data.currentOffice.identificationVerificationRequest
          ?.corporateIdentification;
      const userIdentification =
        data.currentOffice.identificationVerificationRequest
          ?.individualIdentification?.userIdentification;
      const plannedCardHolderCount =
        (corporate
          ? corporateIdentification?.plannedCardHolderCount
          : userIdentification?.plannedCardHolderCount) ?? undefined;
      const plannedUsageAmount =
        (corporate
          ? corporateIdentification?.plannedUsageAmount
          : userIdentification?.plannedUsageAmount) ?? undefined;

      const savedInfo = firstCardRequest && {
        color: firstCardRequest.color ?? undefined,
        name: firstCardRequest.name,
        maximumMonthlyLimit: firstCardRequest.maximumLimitInMonth,
        maximumPaymentLimit: firstCardRequest.maximumPaymentLimit,
        ...(firstCardRequest.managerTenantUser && {
          tenantUser: {
            label: `${firstCardRequest.managerTenantUser.displayName} ${firstCardRequest.managerTenantUser.email}`,
            value: firstCardRequest.managerTenantUser.uid,
          },
          officeCardName: cardName ?? undefined,
        }),
        pin: firstCardRequest.pin ?? undefined,
        plannedCardHolderCount,
        plannedUsageAmount,
      };

      methods.reset(savedInfo ?? undefined);
    },
  });
  const [saveInfo, { loading: savingInfo }] =
    useUpdateFirstCardRequestAndOfficeNameMutation({
      onError: handleMutationError,
    });
  const [saveCorporateInfo, { loading: savingCorporateInfo }] =
    usePatchCorporateIdentificationMutation({
      onError: handleMutationError,
      onCompleted: () => goToStepsOrConfirmation(),
    });
  const [saveIndividualInfo, { loading: savingIndividualInfo }] =
    usePatchIndividualIdentificationMutation({
      onError: handleMutationError,
      onCompleted: () => goToStepsOrConfirmation(),
    });

  const onSubmit = async ({
    tenantUser: { value: managerTenantUserUid },
    maximumMonthlyLimit: maximumLimitInMonth,
    plannedCardHolderCount,
    plannedUsageAmount,
    ...otherInputs
  }: FormData) => {
    if (savingInfo || savingCorporateInfo || savingIndividualInfo) return;

    await saveInfo({
      variables: {
        ...otherInputs,
        managerTenantUserUid,
        maximumLimitInMonth,
      },
    });
    if (corporate) {
      saveCorporateInfo({
        variables: {
          plannedCardHolderCount,
          plannedUsageAmount,
        },
      });
    } else {
      saveIndividualInfo({
        variables: {
          plannedCardHolderCount,
          plannedUsageAmount,
        },
      });
    }
  };

  const onError = () => {
    window.scrollTo(0, 0);
  };

  if (loading) return <Loading />;

  return (
    <FormProvider {...methods}>
      <GlobalMessage />
      <RegistrationAlert />
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        {children}
        <RegistrationButtons />
      </form>
    </FormProvider>
  );
};
