import { Select, SelectProps } from '@moneyforward/cloud-react-ui';
import { Option } from '@moneyforward/cloud-react-ui/lib/components/Select/Select';
import { useState } from 'react';
import styled from 'styled-components';
import { CardsOrder } from '@/graphql';

const StyledSelect = styled(Select)`
  & > .react-select__control {
    min-height: 34px;
  }
  width: 160px;
`;

const options: SelectProps['options'] = [
  { label: '発行日時が古い順', value: CardsOrder.CreatedAtAsc },
  { label: '発行日時が新しい順', value: CardsOrder.CreatedAtDesc },
];
export const LOCAL_STORAGE_SORT_ORDER_KEY = 'sortOrder';
export type SortSelectProps = {
  onOrderChange: (order: CardsOrder | null) => void;
};
export const getSortOrderFromLocalStorage = (
  localStorageValue: string | null
): CardsOrder => {
  return localStorageValue &&
    Object.values(CardsOrder).includes(localStorageValue as CardsOrder)
    ? (localStorageValue as CardsOrder)
    : CardsOrder.CreatedAtAsc;
};
export const CardSortSelect = ({ onOrderChange }: SortSelectProps) => {
  const localStorageValue = window.localStorage.getItem(
    LOCAL_STORAGE_SORT_ORDER_KEY
  );

  const initialSortOrder = getSortOrderFromLocalStorage(localStorageValue);

  const [sortOrderState, setSortOrderState] = useState(initialSortOrder);

  const handleOrderChange = (e: Option) => {
    const newSortOrder = e.value as CardsOrder;
    setSortOrderState(newSortOrder);
    window.localStorage.setItem(LOCAL_STORAGE_SORT_ORDER_KEY, newSortOrder);
    onOrderChange(newSortOrder);
  };

  return (
    <StyledSelect
      options={options}
      clearable={false}
      value={options.find((option) => option.value === sortOrderState)}
      onChange={handleOrderChange}
    />
  );
};
