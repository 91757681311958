import { useFormContext } from 'react-hook-form';
import { TextField } from '@/components/new/TextField';
import { FormError } from '@/components/ui/FormError';
import { PhoneNumberData } from './schema';

export const PhoneNumberInput = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<PhoneNumberData>();

  return (
    <>
      <TextField
        aria-label="phoneNumber"
        {...register('phoneNumber')}
        placeholder="例）0312345678"
      />
      {errors.phoneNumber && (
        <FormError>{errors.phoneNumber.message}</FormError>
      )}
    </>
  );
};
