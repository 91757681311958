import { Select } from '@moneyforward/cloud-react-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { FormError } from '@/components/ui/FormError';
import { PlannedUsageAmountSelectOptions } from '@/lib/plannedUsageAmount';
import { PlannedUsageAmountSchemaData } from './schema';

export const PlannedUsageAmountInput = (): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PlannedUsageAmountSchemaData>();

  return (
    <>
      <Controller
        control={control}
        name="plannedUsageAmount"
        render={({ field }) => (
          <Select
            {...field}
            aria-label="年間のご利用予定額"
            instanceId="plannedUsageAmount"
            placeholder="選択してください"
            options={PlannedUsageAmountSelectOptions}
            value={PlannedUsageAmountSelectOptions.find(
              (option) => option.value === field.value
            )}
            onChange={(e) => field.onChange(e?.value ?? '')}
          />
        )}
      />
      {errors.plannedUsageAmount && (
        <FormError>{errors.plannedUsageAmount.message}</FormError>
      )}
    </>
  );
};
